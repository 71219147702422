import Select from "react-select";
import { SurveyQuestion, SurveyQuestionHiderParams, SurveyQuestionType } from "../../common/types";
import { useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { ValidationOutput } from "../common/ReactHookFormValidation";

interface SurveyFormFieldProps {
  surveyQuestion: SurveyQuestion;
  formMethods: UseFormReturn<FieldValues, any, undefined>;
  onChange: (event: any, surveyQuestionHiderParams: SurveyQuestionHiderParams) => void; 
}

function SurveyFormField({
  surveyQuestion,
  formMethods,
  onChange
}: SurveyFormFieldProps) {
  const [ isOtherSelected, setIsOtherSelected ] = useState<boolean>(false);
  const [ stateValue, setStateValue ] = useState<Array<any> | number | string | undefined>(undefined);

  const register = formMethods.register;

  const checkForOther = (event: any) => {
    if (!event?.target) {
      return;
    }

    const target = event.target;
    var value = target.value

    // unselecting for various use cases
    if (isOtherSelected && 
      (value === undefined // clearing a select
        || surveyQuestion.questionType === SurveyQuestionType.SelectField // choosing another value in a select
        || surveyQuestion.questionType === SurveyQuestionType.RadioField // choosing another value in a radio
      )) {
      setIsOtherSelected(false);
    }

    if (Array.isArray(value)) { // multiselect
      let anyOpenText = false;
      for(let i = 0; i <= value.length; i++) {
        if (value[i] && surveyQuestion.surveyAnswers.find(x => x.id === parseInt(value[i].value))?.isOpenTextAnswer) {
          anyOpenText = true;
          break;
        } 
      }
      setIsOtherSelected(anyOpenText);
    } else {
      if (Number.isNaN(value)) {
        // we only expect ids as values
        return;
      }

      if (surveyQuestion.surveyAnswers.find(x => x.id === parseInt(value))?.isOpenTextAnswer) {
        setIsOtherSelected(!isOtherSelected);
      } 
    }
  }

  const inputField = formMethods.register(surveyQuestion.id.toString(), {
    required: surveyQuestion.isRequired, 
    maxLength: 4000
  });

  switch (surveyQuestion.questionType) {

    case SurveyQuestionType.TextField:
    case SurveyQuestionType.TextNumberField:
    case SurveyQuestionType.TextDateField:
      return (
        <div className="form-field-wrapper">
          <label className={surveyQuestion.isRequired ? 'required' : ''} htmlFor={surveyQuestion.id.toString()}>{surveyQuestion.question}</label>
          <input 
            type={surveyQuestion.questionType === SurveyQuestionType.TextNumberField ? 'number' : surveyQuestion.questionType === SurveyQuestionType.TextDateField ? 'date' : 'text'}
            id={surveyQuestion.id.toString()}             
            {...inputField}
            />
            <ValidationOutput name={surveyQuestion.id.toString()} formMethods={formMethods} />
        </div>
      );
    case SurveyQuestionType.SelectField:
    case SurveyQuestionType.MultiSelectField:
      return (
        <div className="form-field-wrapper">
          <label className={surveyQuestion.isRequired ? 'required' : ''} htmlFor={surveyQuestion.id.toString()}>{surveyQuestion.question}</label>
          <Select 
            id={surveyQuestion.id.toString()} 
            isMulti={surveyQuestion.questionType === SurveyQuestionType.MultiSelectField}
            isClearable={true}
            isSearchable={false}
            {...inputField}            
            onChange={(value: any, event: any) => {
              const val = surveyQuestion.questionType === SurveyQuestionType.MultiSelectField ? value : value?.value;

              inputField.onChange({ target: { ...event, value: val }});
              checkForOther({ target: { ...event, value: val }});
              if (surveyQuestion.questionType !== SurveyQuestionType.MultiSelectField) { // hiding questions not supported on multiselect
                if (stateValue !== undefined) {
                  const answer = surveyQuestion.surveyAnswers?.find(x => x.id === stateValue);
                  if (answer && (answer.hideFromSurveyQuestionId || answer.hideToSurveyQuestionId)) {
                    onChange(event, {
                      currentSurveyQuestionId: surveyQuestion.id, 
                      isAnswerSelected: false, 
                      hideFromSurveyQuestionId: answer.hideFromSurveyQuestionId, 
                      hideToSurveyQuestionId: answer.hideToSurveyQuestionId
                    });
                  }
                }
                const answer = surveyQuestion.surveyAnswers?.find(x => x.id === val);
                if (answer && (answer.hideFromSurveyQuestionId || answer.hideToSurveyQuestionId)) {
                  onChange(event, {
                    currentSurveyQuestionId: surveyQuestion.id, 
                    isAnswerSelected: true, 
                    hideFromSurveyQuestionId: answer.hideFromSurveyQuestionId, 
                    hideToSurveyQuestionId: answer.hideToSurveyQuestionId
                  });
                }
              }
              setStateValue(val);
            }}
            options={
              surveyQuestion.surveyAnswers?.sort((a, b) => a.sortOrder - b.sortOrder).map(x => {
                return {
                  label: x.answer,
                  value: x.id
                };  
              }) ?? []
            }
          ></Select>
          <ValidationOutput name={surveyQuestion.id.toString()} formMethods={formMethods} />
          {isOtherSelected && surveyQuestion.surveyAnswers?.some(x => x.isOpenTextAnswer) && (
            <input type="text" className="mt-2 w-100" {...register("other" + surveyQuestion.id.toString(), {maxLength: 4000})} />
          )}
          <ValidationOutput name={"other" + surveyQuestion.id.toString()} formMethods={formMethods} />
        </div>
      );
      case SurveyQuestionType.RadioField:
      case SurveyQuestionType.LikertScaleField:
        return (
          <div className="form-field-wrapper">
            <label className={surveyQuestion.isRequired ? 'required' : ''} htmlFor={surveyQuestion.id.toString()}>{surveyQuestion.question}</label>
            <div className={surveyQuestion.questionType === SurveyQuestionType.LikertScaleField ? 'likert' : ''}>
            {surveyQuestion.surveyAnswers?.sort((a, b) => a.sortOrder - b.sortOrder).map(x => {
              return <div key={x.id}>
              <label>
              <input 
              type="radio" 
              id={x.id.toString()} 
              value={x.id.toString()} 
              {...inputField}         
              onChange={(e) => {
                inputField.onChange(e);
                checkForOther(e);
                if (stateValue !== undefined) {
                  const answer = surveyQuestion.surveyAnswers?.find(y => y.id === stateValue);
                  if (answer && (answer.hideFromSurveyQuestionId || answer.hideToSurveyQuestionId)) {
                    onChange(e, {
                      currentSurveyQuestionId: surveyQuestion.id, 
                      isAnswerSelected: false, 
                      hideFromSurveyQuestionId: answer.hideFromSurveyQuestionId, 
                      hideToSurveyQuestionId: answer.hideToSurveyQuestionId
                    });
                  }
                }
                const answer = surveyQuestion.surveyAnswers?.find(y => y.id === x.id);
                if (answer && (answer.hideFromSurveyQuestionId || answer.hideToSurveyQuestionId)) {
                  onChange(e, {
                    currentSurveyQuestionId: surveyQuestion.id, 
                    isAnswerSelected: true, 
                    hideFromSurveyQuestionId: answer.hideFromSurveyQuestionId, 
                    hideToSurveyQuestionId: answer.hideToSurveyQuestionId
                  });
                }
                setStateValue(x.id);
              }} 
              /> {x.answer}</label>
              {isOtherSelected && x.isOpenTextAnswer && (
                <input type="text" {...register("other" + surveyQuestion.id.toString(), {maxLength: 4000})} />
              )}
              <ValidationOutput name={"other" + surveyQuestion.id.toString()} formMethods={formMethods} />
              </div>
            })}
            </div>
            <ValidationOutput name={surveyQuestion.id.toString()} formMethods={formMethods} />
          </div>
        );
      case SurveyQuestionType.CheckField:
        return (
          <div className="form-field-wrapper">
            <label className={surveyQuestion.isRequired ? 'required' : ''}>
            <input 
            type="checkbox" 
            id={surveyQuestion.id.toString()} 
            value={surveyQuestion.id.toString()} 
            {...inputField}         
            onChange={(e) => {
              inputField.onChange(e);
              checkForOther(e);
              if(surveyQuestion.surveyAnswers?.at(0)?.hideFromSurveyQuestionId || surveyQuestion.surveyAnswers?.at(0)?.hideToSurveyQuestionId) {
                onChange(e, {
                  currentSurveyQuestionId: surveyQuestion.id, 
                  isAnswerSelected: e.target.checked, 
                  hideFromSurveyQuestionId: surveyQuestion.surveyAnswers?.at(0)?.hideFromSurveyQuestionId, 
                  hideToSurveyQuestionId: surveyQuestion.surveyAnswers?.at(0)?.hideToSurveyQuestionId
                });
              }
            }} 
            /> {surveyQuestion.surveyAnswers?.at(0)?.answer}</label>    
            <ValidationOutput name={surveyQuestion.id.toString()} formMethods={formMethods} />    
            {isOtherSelected && surveyQuestion.surveyAnswers?.some(x => x.isOpenTextAnswer) && (
              <input type="text" {...register("other" + surveyQuestion.id.toString(), {maxLength: 4000})} />
            )}
            <ValidationOutput name={"other" + surveyQuestion.id.toString()} formMethods={formMethods} />
          </div>
        );
      case SurveyQuestionType.MultiCheckField:
        return (
          <div className="form-field-wrapper">
            <label className={surveyQuestion.isRequired ? 'required' : ''} htmlFor={surveyQuestion.id.toString()}>{surveyQuestion.question}</label>
            {surveyQuestion.surveyAnswers?.sort((a, b) => a.sortOrder - b.sortOrder).map(x => {
              return <div key={x.id}>
              <label>
              <input 
              type="checkbox" 
              id={x.id.toString()} 
              value={x.id.toString()} 
              {...inputField}         
              onChange={(e) => {
                inputField.onChange(e);
                checkForOther(e);
                if(x.hideFromSurveyQuestionId || x.hideToSurveyQuestionId) {
                  onChange(e, {
                    currentSurveyQuestionId: surveyQuestion.id, 
                    isAnswerSelected: e.target.checked, 
                    hideFromSurveyQuestionId: x.hideFromSurveyQuestionId, 
                    hideToSurveyQuestionId: x.hideToSurveyQuestionId
                  });
                }
              }} 
              /> {x.answer}</label>
              {isOtherSelected && x.isOpenTextAnswer && (
                <input type="text" {...register("other" + surveyQuestion.id.toString(), {maxLength: 4000})} />
              )}
              <ValidationOutput name={"other" + surveyQuestion.id.toString()} formMethods={formMethods} />
              </div>
            })}
            <ValidationOutput name={surveyQuestion.id.toString()} formMethods={formMethods} />
          </div>
        );
      case SurveyQuestionType.TextAreaField:
        return (
          <div className="form-field-wrapper">
            <label className={surveyQuestion.isRequired ? 'required' : ''} htmlFor={surveyQuestion.id.toString()}>{surveyQuestion.question}</label>
            <textarea id={surveyQuestion.id.toString()} 
              {...inputField}         
            ></textarea>
            <ValidationOutput name={surveyQuestion.id.toString()} formMethods={formMethods} />
          </div>
        );
      default:
        return (
          <div>Unexpected field</div>
        );
  }
  
};

export default SurveyFormField;

