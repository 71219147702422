import { SurveyConfirmationState } from "../../../common/types";
import { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router';
import Loading from '../../common/Loading';
import { AppLabels } from '../../../common/localization';


interface ISurveyConfirmationProps {}

class SurveyConfirmation extends Component<ISurveyConfirmationProps & RouteComponentProps, SurveyConfirmationState>{
    constructor(props: ISurveyConfirmationProps & RouteComponentProps) {
        super(props);
        const stateData: SurveyConfirmationState = this.props.history.location.state as SurveyConfirmationState;
        this.state = stateData;          
      }
    
      async componentDidMount() {
        try {
          if (this.state.message) {
            this.setState({
              ready: true
            });
          } else {
            this.setState({
              serverError: "No state data"
            })
          }
        } catch(err: any) {
          this.setState({
            serverError: err
          })
        }
      }

      render() {
        let { message, ready } = this.state;
    
        if(!ready) {
          return (<div className="my-3">
              <Loading label="Loading..." color="primary"></Loading>
            </div>)
        }
    
        if(message === undefined) {
          return (
            <div className="my-3">
              <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: AppLabels.ErrorUnexpected.replace(/(<? *script)/gi, 'illegalscript')}}></div>
            </div>
          )
        }
    
        return (

          <div className="mt-2">
            <h1>Survey Submitted</h1>
            <h3 dangerouslySetInnerHTML={{__html: message.replace(/(<? *script)/gi, 'illegalscript')}}></h3>
          </div>

        )
    }    
}

export default withRouter(SurveyConfirmation);
