import { useEffect, useState } from "react";
import QueryString from "query-string"
import SurveyForm from "../SurveyForm";
import { SurveyConfirmationState } from "../../../common/types";
import { useHistory } from "react-router";

function InvalidPostTestSurvey() {
  const history = useHistory();
  const [ userId, setUserId ] = useState<string | undefined>(undefined);
  const [ submitted, setSubmitted ] = useState<boolean>(false);

  useEffect(() => {
    let params = QueryString.parse(window.location.search)
    setUserId(Array.isArray(params.userID) ? params.userID[0] : (params.userID || undefined))
  }, []);

  const onSubmit = () => {
    setSubmitted(true); 
    history.push("/survey-confirmation", 
    { message: "Thank you for completing the survey! Your contributions will help us improve our program. Continue to order more HIV self-tests or connect with our peer navigators!"} as SurveyConfirmationState
    );
  }

  return (
    <div className="mt-2">
      <h1>I’m Ready Jamaica – Invalid Post-Test Survey</h1>
      {!userId && (
        <div className="my-3">
          <div className="alert alert-danger">No User ID given.</div>
        </div>
      )}
      {userId !== undefined && userId.trim().length > 0 && !submitted && (
        <div>
          <p>Please tell us a bit more about your invalid self-test result.</p>
          <p>There are many reasons why you may have an invalid test result. While the OraQuick HIV self-test is very accurate, its instructions have to be followed exactly. If you decide to take another test, we recommend reading the instructions carefully, watching the video that you can get to through the QR code in your test kit insert or booking an appointment with a Peer Navigator.</p>
          <SurveyForm surveyId={3} userId={userId} onSubmitted={onSubmit} ></SurveyForm>
        </div>
      )}
    </div>
  );

}

export default InvalidPostTestSurvey;