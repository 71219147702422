import { Component } from 'react'
import { HomeState } from '../../common/types';
import { RouteComponentProps, withRouter } from 'react-router';
import Loading from '../common/Loading';
import { AppLabels } from '../../common/localization';
import { stringTemplate } from '../../common/Helpers';
import proceed from '../../assets/images/proceed.png'
import connect from '../../assets/images/connect.png'
import back from '../../assets/images/back.png'

interface IConfirmationProps {}

class ConfirmationV2 extends Component<IConfirmationProps & RouteComponentProps, HomeState>{
    constructor(props: IConfirmationProps & RouteComponentProps) {
        super(props);
        const stateData: HomeState = this.props.history.location.state as HomeState;
        this.state = stateData;          
      }
    
      async componentDidMount() {
        try {
          if (this.state.data) {
            this.setState({
              ready: true
            });
          } else {
            this.setState({
              serverError: "No state data"
            })
          }
        } catch(err: any) {
          this.setState({
            serverError: err
          })
        }
      }

      render() {
        let { data, hasSubmitted, ready, kitDelivered } = this.state
    
        if(!ready) {
          return (<div className="my-3">
              <Loading label="Loading..." color="primary"></Loading>
            </div>)
        }
    
        if(data === undefined) {
          return (
            <div className="my-3">
              <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: AppLabels.ErrorUnexpected.replace(/(<? *script)/gi, 'illegalscript')}}></div>
            </div>
          )
        }
    
        return (
          <div className="my-3">    
    
              {hasSubmitted && kitDelivered &&
                <div className="my-3">
                  <p dangerouslySetInnerHTML={{__html: stringTemplate(AppLabels.KitMarkedDelivered, {
                    '1': `<img src='${proceed}' alt='proceed button' />`
                  }).replace(/(<? *script)/gi, 'illegalscript')}}></p>
                </div>
              }
    
              {hasSubmitted && !kitDelivered &&
              <div>
                <div className="my-3">
                  <p dangerouslySetInnerHTML={{__html: stringTemplate(AppLabels.KitMarkedNotDelivered, {
                    '1': `<img src='${back}' alt='back button' />`,
                    '2': `<img src='${connect}' alt='connect button' />`
                  }).replace(/(<? *script)/gi, 'illegalscript')}}></p>
                </div>
              </div>  
              }

          </div>
        )
    }    
}

export default withRouter(ConfirmationV2);