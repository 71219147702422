import axios from "axios";
import { SurveySubmission } from "../common/types";

const action = "survey";

export async function getSurvey(surveyId: number): Promise<any> {
  return await axios.get(`/api/${action}/${surveyId}`)
}

export async function postSurveySubmission(surveySubmission: SurveySubmission): Promise<any> {
  return await axios.post(`/api/${action}/survey-submission`, surveySubmission)
}