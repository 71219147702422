import { Component } from 'react'
import { Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { Layout } from './Layout'
import HomeV2 from '../v2/Home'
import SummaryV2 from '../v2/Summary'
import ConfirmationV2 from '../v2/Confirmation'
import PreTestSurvey from '../survey/pages/PreTestSurvey'
import PostTestSurvey from '../survey/pages/PostTestSurvey'
import InvalidPostTestSurvey from '../survey/pages/InvalidPostTestSurvey'
import FollowUpSurvey from '../survey/pages/FollowUpSurvey'
import SurveyConfirmation from '../survey/pages/SurveyConfirmation'

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Route exact path='/v2' component={HomeV2} />
          <Route exact path='/v2/summary' component={SummaryV2} />
          <Route exact path='/v2/confirmation' component={ConfirmationV2} />
          <Route exact path='/pre-test-survey' component={PreTestSurvey} />
          <Route exact path='/post-test-survey' component={PostTestSurvey} />
          <Route exact path='/invalid-post-test-survey' component={InvalidPostTestSurvey} />
          <Route exact path='/follow-up-survey' component={FollowUpSurvey} />
          <Route exact path='/survey-confirmation' component={SurveyConfirmation} />
        </Layout>
      </BrowserRouter>
    )
  }
}
