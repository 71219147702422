import { useCallback, useEffect, useState } from "react";
import { EndpointResponse, Survey, SurveySubmissionAnswer, SurveySubmission, SurveyQuestionHiderParams } from "../../common/types";
import { getSurvey, postSurveySubmission } from "../../api/surveys";
import { AppLabels } from "../../common/localization";
import Loading from "../common/Loading";
import SurveyFormField from "./SurveyFormField";
import { useForm } from "react-hook-form";
import { groupBy } from "../../common/Helpers";

interface SurveyFormProps {
  surveyId: number;
  userId: string;
  onSubmitted?: (data: any) => void;
}

interface SurveyQuestionHide {
  surveyQuestionId: number,
  isHidden: boolean
}

function SurveyForm({
  surveyId,
  userId,
  onSubmitted
}: SurveyFormProps) {
  const methods = useForm();
  const [ ready, setReady ] = useState<boolean>(false);
  const [ survey, setSurvey ] = useState<Survey | undefined>(undefined);
  const [ serverError, setServerError ] = useState<string>("");
  const [ submitting, setSubmitting ] = useState<boolean>(false);
  const [ surveyQuestionHider, setSurveyQuestionHider ] = useState<Array<SurveyQuestionHide> | undefined>(undefined);

  let getSurveyForm = useCallback(() => {
    setReady(false);

    if(surveyId === undefined) {
      setServerError(AppLabels.CantSubmitErrorInternal);
      return;
    }

    getSurvey(surveyId)
      .then((resp: any) => {        
        let responseData: EndpointResponse = resp.data

        if(responseData.status) {
          const surveyResponse = responseData.data as Survey;
          setSurvey(surveyResponse);

          if(surveyResponse && surveyResponse.surveyQuestions?.length) {
            let surveyQuestionsHidden = new Array<SurveyQuestionHide>();
            for(let i = 0; i < surveyResponse.surveyQuestions.length; i++) {
              surveyQuestionsHidden.push({surveyQuestionId: surveyResponse.surveyQuestions[i].id, isHidden: false});
            }
            setSurveyQuestionHider(surveyQuestionsHidden);
          }

          setReady(true)
        } else {
          setServerError(AppLabels.CantSubmitErrorInternal);
        }
      })
      .catch((err: any) => {
        let message = err !== undefined && err.response !== undefined && err.response.data !== undefined && err.response.data.length > 0
          ? err.response.data
          : AppLabels.CantSubmitErrorInternal
        setServerError(message);

        setReady(true)
      });
  }, [ setReady, setSurvey, setServerError, surveyId ])

  useEffect(() => {
    getSurveyForm();
  }, [ getSurveyForm ]);

  const updateSurveyQuestionHider = (event: any, surveyQuestionHiderParams: SurveyQuestionHiderParams) => {
    if (surveyQuestionHider === undefined || surveyQuestionHiderParams === undefined) {
      return;
    }
    
    let sqh = surveyQuestionHider;
    for(let i = 0; i < sqh.length; i++) {
      if(surveyQuestionHiderParams.hideFromSurveyQuestionId) {
        if(sqh[i].surveyQuestionId >= surveyQuestionHiderParams.hideFromSurveyQuestionId &&
          (!surveyQuestionHiderParams.hideToSurveyQuestionId || sqh[i].surveyQuestionId <= surveyQuestionHiderParams.hideToSurveyQuestionId)
        ) {
          sqh[i].isHidden = surveyQuestionHiderParams.isAnswerSelected;
        }
      }
    }
    setSurveyQuestionHider([...sqh]);
  }

  const getSurveySubmissionAnswer = (surveyQuestionId: number, surveyAnswerId: number, otherText: string | undefined): SurveySubmissionAnswer => {
    if (survey && survey.surveyQuestions.find(x => x.id === surveyQuestionId)?.surveyAnswers.find(x => x.id === surveyAnswerId)?.isOpenTextAnswer) {
      return {surveyAnswerId: surveyAnswerId, otherText: otherText} as SurveySubmissionAnswer;
    } else {
      return {surveyAnswerId: surveyAnswerId} as SurveySubmissionAnswer;
    }
  }

  const onSubmit = (formData: any) => {
    
    if (!survey || submitting) {
      return;
    }

    setSubmitting(true);
 
    let surveySubmission: SurveySubmission = {
      surveyId: surveyId,
      key: userId,
      surveySubmissionAnswers: []
    };

    for (let [key, value] of Object.entries(formData)) {
      if (!key || !value || isNaN(parseInt(key))) {
        // we only expect ids as keys except for the text field "other" which we handle on the id loop for that field
        continue;
      }

      const surveyQuestionId = parseInt(key as string);
      if (surveyQuestionHider !== undefined && surveyQuestionHider.find(x => x.surveyQuestionId === surveyQuestionId)?.isHidden) {
        // not saving fields our logic has deemed to be hidden based on backend field config
        continue;
      }

      if (Array.isArray(value)) {
        value.map(x => {
          return surveySubmission.surveySubmissionAnswers.push(getSurveySubmissionAnswer(surveyQuestionId, parseInt(x as string), formData.hasOwnProperty('other' + key) ? formData['other' + key] : undefined));
        });
      } else {
        surveySubmission.surveySubmissionAnswers.push(getSurveySubmissionAnswer(surveyQuestionId, parseInt(value as string), formData.hasOwnProperty('other' + key) ? formData['other' + key] : undefined));
      }
      
    }

    postSurveySubmission(surveySubmission)
      .then((resp: any) => {      
        let responseData: EndpointResponse = resp.data
        setSubmitting(false);  

        if(responseData.status) {
          if (onSubmitted && typeof(onSubmitted) === "function") {
            onSubmitted(responseData.data);
          }
        } else {
          setServerError(AppLabels.CantSubmitErrorInternal);
        }
      })
      .catch((err: any) => {
        setSubmitting(false);    
        let message = err !== undefined && err.response !== undefined && err.response.data !== undefined && err.response.data.length > 0
          ? err.response.data
          : AppLabels.CantSubmitErrorInternal
        setServerError(message);
      });
  };

  return (
    <div className="survey-form-wrapper">
      {!ready && (
        <div className="my-3">
          <Loading label="Loading form..." color="primary"></Loading>
        </div>
      )}

      {ready && serverError.length > 0 && (
        <div className="my-3">
          {serverError}
        </div>
      )}

      {ready && serverError.length === 0 && survey !== undefined && surveyQuestionHider !== undefined && (
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate autoComplete="off">
          {survey.surveyQuestions.some(x => x.groupName.length > 0) &&  
            Object.entries(groupBy(survey.surveyQuestions.sort((a, b) => a.sortOrder - b.sortOrder), "groupName")).map((groupAndQuestions, index) => {
              return <div key={index}>
                {groupAndQuestions[0] && <h3>{groupAndQuestions[0]}</h3>}
                {groupAndQuestions[1].sort((a, b) => a.sortOrder - b.sortOrder).map(y => {   
                  return surveyQuestionHider.find(z => z.surveyQuestionId === y.id)!.isHidden === false &&
                    <SurveyFormField key={y.id} surveyQuestion={y} formMethods={methods} onChange={updateSurveyQuestionHider}></SurveyFormField>
                })}
              </div>
            })
          }
          {!survey.surveyQuestions.some(x => x.groupName.length > 0) && survey.surveyQuestions.sort((a, b) => a.sortOrder - b.sortOrder).map(x => {
            return surveyQuestionHider.find(z => z.surveyQuestionId === x.id)!.isHidden === false &&
              <SurveyFormField key={x.id} surveyQuestion={x} formMethods={methods} onChange={updateSurveyQuestionHider}></SurveyFormField>
          })}
          <div className="text-right mb-3">
            <button className="btn btn-primary" type="submit" disabled={submitting}>
              {submitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      )}
    </div>
  )

}

export default SurveyForm;