export function makeID(length: number): string {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function makeFirstName(): string {
  const names = ["Ainsley", "Andre", "Anthony", "Aston", "Barrington", "Byron", "Carlton", "Clive", "Damien", "Dwayne", "Everton", "Fabian", "Gareth", "Horace", "Ian", "Jamal", "Jermaine", "Kevin", "Leroy", "Marlon", "Nigel", "Omar", "Patrick", "Quincy", "Rohan", "Shane", "Trevor", "Upton", "Vernon", "Winston", "Xavier", "Yannick", "Zebedee", "Adrian", "Barry", "Cecil", "Delroy", "Errol", "Floyd", "Gary", "Hayden", "Irving", "Jason", "Keith", "Lloyd", "Mark", "Neil", "Orville", "Paul", "Ricardo", "Sean", "Terrence", "Uriah", "Vincent", "Wayne", "Zane", "Alfonso", "Bernard", "Curtis", "Donovan", "Earl", "Franklin", "Gilbert", "Hamilton", "Isaac", "Jerome", "Kendall", "Linton", "Maurice", "Noel", "Oswald", "Patrick", "Quentin", "Roderick", "Sheldon", "Tafari", "Ulric", "Vaughn", "Willis", "Xavier", "Yusef", "Zephyr", "Alan", "Brian", "Chad", "Devon", "Ervin", "Fabio", "Gideon", "Hugh", "Idris", "Jamar", "Kenyon", "Lamar", "Malik", "Norris", "O'Neil", "Perry", "Ralston", "Stacey", "Tariq", "Ulrich", "Virgil", "Winston", "Xavier", "Yannick", "Zephaniah", "Alaric", "Barry", "Conroy", "Darnell", "Everton", "Floyd", "Gordon", "Horatio", "Inigo", "Jovan", "Kieron", "Llewellyn", "Martell", "Niles", "Octavio", "Percival", "Quentin", "Ralston", "Stacey", "Tariq", "Upton", "Vernon", "Winton", "Xander", "Yusef", "Zaire", "Alaric", "Brandon", "Clayton", "Darius", "Errol", "Fabian", "Garrett", "Hugh"];

  return names[Math.floor(Math.random() * names.length)];
}

export function makeLastName(): string {
  const names = ["Thompson", "Campbell", "Foster", "Green", "Hall", "Morgan", "Powell", "Edwards", "Nelson", "Brown", "Bennett", "White", "Clarke", "Walker", "Robinson", "Lewis", "Thompson", "Mitchell", "Wright", "Davis", "Hall", "Williams", "Morgan", "James", "Grant", "Edwards", "Jackson", "Lee", "Taylor", "Thomas", "Henry", "Morris", "Thompson", "Palmer", "Johnson", "Campbell", "Stewart", "Gordon", "Brooks", "Young", "Thompson", "Wright", "Allen", "Green", "Nelson", "Russell", "Brown", "Carter", "Morgan", "Martin", "Johnson", "Mitchell", "Walker", "Bailey", "Wright", "Bennett", "Williams", "Foster", "Hall", "Anderson", "Thomas", "Brown", "Edwards", "Miller", "Thompson", "Johnson", "Green", "Campbell", "Clarke", "Williams", "Young", "Wright", "Foster", "James", "Nelson", "Williams", "Taylor", "Wright", "Johnson", "Thompson", "Hall", "Brown", "Campbell", "Wright", "Johnson", "Anderson", "Williams", "Brown", "Campbell", "Johnson", "Wright", "Thomas", "Brown", "Miller", "Campbell", "Thomas", "Foster", "Anderson", "Thompson", "Wright", "Williams", "Johnson", "Brown", "Wright", "Campbell", "Miller", "Thompson", "Wright", "Brown", "Campbell", "Foster", "Brown", "Campbell", "Johnson", "Wright", "Thompson", "Miller", "Brown", "Campbell", "Johnson", "Wright", "Brown", "Campbell", "Johnson", "Wright", "Campbell", "Brown", "Johnson", "Wright", "Campbell", "Johnson", "Wright", "Campbell", "Brown", "Johnson", "Wright", "Campbell", "Brown", "Johnson", "Wright", "Campbell"];
  
  return names[Math.floor(Math.random() * names.length)];
}
