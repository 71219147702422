import { useEffect, useState } from "react";
import QueryString from "query-string"
import SurveyForm from "../SurveyForm";
import { useHistory } from "react-router";
import { SurveyConfirmationState } from "../../../common/types";
import proceed from '../../../assets/images/proceed.png'
import connect from '../../../assets/images/connect.png'

function PostTestSurvey() {
  const history = useHistory();
  const [ userId, setUserId ] = useState<string | undefined>(undefined);
  const [ submitted, setSubmitted ] = useState<boolean>(false);

  useEffect(() => {
    let params = QueryString.parse(window.location.search)
    setUserId(Array.isArray(params.userID) ? params.userID[0] : (params.userID || undefined))
  }, []);

  const onSubmit = () => {
    setSubmitted(true);    
    history.push("/survey-confirmation", 
    { message: `Thank you for completing the survey! Your contributions will help us improve our program. Tap Proceed <img src='${proceed}' alt='proceed button' /> at the top of the screen to continue and find more information about connecting to care. Remember you can get up to 3 kits, tap Order your free kits option in the menu. At any point, you can always talk to our Peer Navigators using the Connect button <img src='${connect}' alt='connect button' /> at the bottom of the app!`} as SurveyConfirmationState
    );
  }

  return (
    <div className="mt-2">
      <h1>I’m Ready Jamaica – Post-Test Survey</h1>
      {!userId && (
        <div className="my-3">
          <div className="alert alert-danger">No User ID given.</div>
        </div>
      )}
      {userId !== undefined && userId.trim().length > 0 && !submitted && (
        <div>
          <p>Thank you for sharing your test result with us!</p>
          <p>Please remember that there are resources available such as peer navigators online to help you connect to the services you need.</p>
          <SurveyForm surveyId={2} userId={userId} onSubmitted={onSubmit} ></SurveyForm>
        </div>
      )}
    </div>
  );

}

export default PostTestSurvey;