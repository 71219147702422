import {
  FormSubmission,
  ShipmentType,
  UserConfig,
  FormSubmissionKit,
} from './types'
import {
  getNumKitsNeeded,
  getNumKitsPreviouslyOrdered,
  getNumKitsRemaining,
} from './Helpers'
import Constants from './constants'

const validator = {
  validateFormSubmission: (
    data: FormSubmission,
    userConfig: UserConfig,
    validateLocation: boolean,
    validateUserKitOrders: boolean
  ): boolean => {
    const {
      UserID,
      FirstName,
      LastName,
      StreetName,
      City,
      LocationID,
      PhoneNumber,
    } = data

    var validAmountsNeeded = true

    if (validateUserKitOrders) {
      validAmountsNeeded =
        userConfig.kitsAvailable.length > 0 &&
        userConfig.kitsAvailable.reduce((acc: boolean, kit) => {
          let amountRequested = getNumKitsNeeded(data.Kits, kit.key)
          let amountRemaining = getNumKitsRemaining(
            userConfig.kitsOrdered,
            kit.key
          )
          return acc && amountRequested <= amountRemaining
        }, true)
    }

    return (
      UserID !== undefined &&
      validAmountsNeeded &&
      (data.ShipmentType === ShipmentType.Pickup ||
        (FirstName.length > 0 &&
          LastName.length > 0 &&
          StreetName.length > 2 &&
          PhoneNumber.length > 14 &&
          City.length > 1)) &&
      (!validateLocation ||
        data.ShipmentType === ShipmentType.Delivery ||
        LocationID > 0)
    )
  },



  isKitQuantityValid: (
    kitType: string,
    kits: FormSubmissionKit[],
    config: UserConfig
  ) => {
    let maxQuantity = getNumKitsRemaining(config.kitsOrdered, kitType)
    let orderedQuantity = getNumKitsNeeded(kits, kitType)

    if (kitType === Constants.HivKitCode) {
      let numPreviouslyOrdered = getNumKitsPreviouslyOrdered(
        config.kitsOrdered,
        kitType
      )

      // You must order at least one HIV kit unless you've already ordered one in which case ordering 0 is allowed (in case they want their covid kit)
      return (
        (numPreviouslyOrdered > 0 && orderedQuantity === 0) ||
        (orderedQuantity > 0 && orderedQuantity <= maxQuantity)
      )
    } else {
      // For any other kits just make sure they still have quota available
      return orderedQuantity <= maxQuantity
    }
  },
}

export default validator
