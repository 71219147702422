import { FieldValues, UseFormReturn, get } from "react-hook-form";

type IValidationOutput = {
  name: string;
  formMethods: UseFormReturn<FieldValues, any, undefined>;
};

export function ValidationOutput({ name, formMethods }: IValidationOutput) {
  const { errors } = formMethods.formState;

  let error = get(errors, name);

  return (
    <>
      {error && (
        <span role="alert" className="text-danger">
          {error.message.length > 0 ? (
            error.message
          ) : (
            <>
              {error.type === "required" ? "This field is required" : ""}
              {error.type === "maxLength"
                ? "This is greater than the allowed maximum length"
                : ""}
              {error.type === "max"
                ? "This is greater than the allowed maximum"
                : ""}
              {error.type === "minLength"
                ? "This is less than the allowed minimum length"
                : ""}
              {error.type === "min"
                ? "This is less than the allowed minimum"
                : ""}
              {error.type === "pattern" || error.type === "validate"
                ? "This is not valid"
                : ""}
            </>
          )}
        </span>
      )}
    </>
  );
}