import { useEffect, useState } from "react";
import QueryString from "query-string"
import SurveyForm from "../SurveyForm";
import { useHistory } from "react-router";
import { SurveyConfirmationState } from "../../../common/types";
import proceed from '../../../assets/images/proceed.png'

function PreTestSurvey() {
  const history = useHistory();
  const [ userId, setUserId ] = useState<string | undefined>(undefined);
  const [ submitted, setSubmitted ] = useState<boolean>(false);

  useEffect(() => {
    let params = QueryString.parse(window.location.search)
    setUserId(Array.isArray(params.userID) ? params.userID[0] : (params.userID || undefined))
  }, []);

  const onSubmit = () => {
    setSubmitted(true);    
    history.push("/survey-confirmation", 
    { message: `Thank you for completing the survey! Your contributions will help us improve our program. Tap Proceed <img src='${proceed}' alt='proceed button' /> at the top of the screen to continue to order your free HIV self-test kits and for information on how to perform the test!`} as SurveyConfirmationState
    );
  }

  return (
    <div className="mt-2">
      <h1>I’m Ready Jamaica – Pre-Test Survey</h1>
      {!userId && (
        <div className="my-3">
          <div className="alert alert-danger">No User ID given.</div>
        </div>
      )}
      {userId !== undefined && userId.trim().length > 0 && !submitted && (
        <div>
          <p>Welcome to I’m Ready Jamaica! Thank you for creating your profile. We want to make sure that everyone can get access to HIV self-testing. Help us make sure this program works for everyone by taking this 5 minute anonymous survey.</p>
          <SurveyForm surveyId={1} userId={userId} onSubmitted={onSubmit} ></SurveyForm>
        </div>
      )}
    </div>
  );

}

export default PreTestSurvey;