import { Component } from 'react'
import { HomeState, ShipmentType, KitDeliveredRequest, EndpointResponse, ErrorCause } from '../../common/types';
import { AppLabels } from '../../common/localization';
import { Spinner } from 'react-bootstrap';
import Loading from '../common/Loading';
import { RouteComponentProps, withRouter } from 'react-router';
import { markKitDelivered } from '../../api/kits';

interface ISummaryProps {}

class SummaryV2 extends Component<ISummaryProps & RouteComponentProps, HomeState>{
  constructor(props: ISummaryProps & RouteComponentProps) {
    super(props);
    const stateData: HomeState = this.props.history.location.state as HomeState;
    this.state = stateData;
      
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      if (this.state.data) {
        this.setState({
          ready: true
        });
      } else {
        this.setState({
          serverError: "No state data"
        })
      }
    } catch(err: any) {
      this.setState({
        serverError: err
      })
    }
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    let { data, lang } = this.state
    this.setState({ isSaving: true });

    // update kit
    const request: KitDeliveredRequest = {
      transactionId: data.Id,
      userId: data.UserID
    };
    markKitDelivered(lang, request)
    .then((resp: any) => {
      let responseData: EndpointResponse = resp.data
      let serverError = ""

      if(responseData.status) {
        this.setState({ kitDelivered: true });
        this.props.history.push("/v2/confirmation", this.state as HomeState);
      } else {
        if(responseData.reason === ErrorCause.SubmissionDisabled) {
          serverError = AppLabels.NotLiveNotice;
        } else {          
          serverError = AppLabels.CantSubmitErrorInternal;
        }

        this.setState({ 
          isSaving: false,
          serverError: serverError
        })
      }
    }).catch(err => {
        let message = err !== undefined && err.response !== undefined && err.response.data !== undefined && err.response.data.length > 0
          ? err.response.data
          : AppLabels.CantSubmitErrorInternal

      this.setState({ 
        isSaving: false,
        serverError: message
      })
    });
  }

  handleNotDelivered = () => {    
    this.props.history.push("/v2/confirmation", this.state as HomeState);
  }

  render() {
    let { data, hasSubmitted, serverError, generatedTokensJson, isSaving, ready } = this.state

    if(!ready) {
      return (<div className="my-3">
          <Loading label="Loading..." color="primary"></Loading>
        </div>)
    }

    if(data === undefined) {
      return (
        <div className="my-3">
          <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: AppLabels.ErrorUnexpected.replace(/(<? *script)/gi, 'illegalscript')}}></div>
        </div>
      )
    }

    return (
      <div className="my-3">
        <form onSubmit={this.handleSubmit}>

          {hasSubmitted && 
            console.log(`ImReadyForm ${JSON.stringify(generatedTokensJson)}`)
          }

          {data.ShipmentType === ShipmentType.Delivery && hasSubmitted && 
          <div>
            <div className="my-3">
              <p dangerouslySetInnerHTML={{__html: AppLabels.FormSubmittedDelivery.replace(/(<? *script)/gi, 'illegalscript')}}></p>
            </div>

            <div className='mb-2'>
              <div className="form-title text-center fw-bold fsx2">Random Delivery Name: {data.FirstName} {data.LastName}</div>
            </div>

            <div className='mb-2'>
              <div className="form-title">{AppLabels.DeliveryAddressTitle}:</div>
            </div>

            <div className="row">
              <div className='col-6 col-md-3'>{AppLabels.BuildingNumber}</div>
              <div className='col-6 col-md-3'>{data.BuildingNumber}</div>
              <div className='col-6 col-md-3'>{AppLabels.StreetName}</div>
              <div className='col-6 col-md-3'>{data.StreetName}</div>
            </div>

            <div className="row">
              <div className='col-6 col-md-3'>{AppLabels.Unit}</div>
              <div className='col-6 col-md-3'>{data.Unit}</div>
              <div className='col-6 col-md-3'>{AppLabels.City}</div>
              <div className='col-6 col-md-3'>{this.state.cities.find(x => x.value === data.City)?.label}</div>
            </div>

            <div className="row">
              <div className='col-6 col-md-3'>{AppLabels.PhoneNumberTitle}</div>
              <div className='col-6 col-md-9'>{data.PhoneNumber}</div>
            </div>

          </div>}

          {serverError !== undefined && serverError.trim().length > 0 && (
            <p className="mb-3 alert alert-danger" dangerouslySetInnerHTML={{__html: serverError.replace(/(<? *script)/gi, 'illegalscript')}}></p>
          )}

          <div className="row mt-3 mb-3">
            <div className='col-12'>
              {isSaving 
              ? <button type="submit" disabled className="btn btn-secondary w-100"><Spinner animation="border" variant="primary" className="me-2" size="sm" />{AppLabels.Saving}</button>
              : <button type="submit" className="btn btn-secondary w-100">{AppLabels.KitDelivered}</button>
              }
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className='col-12'>
              <button type="button" onClick={this.handleNotDelivered} className="btn btn-primary w-100">{AppLabels.KitNotDelivered}</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
    
}


export default withRouter(SummaryV2);