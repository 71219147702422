import { AppLabels } from './localization'

export type SelectOption = {
  label: any
  value: any
}

export type SelectOptionGroup = {
  label: any
  value: string
  options: SelectOption[]
}

export enum ErrorCause {
  Unknown = 1,
  TooManyKitsRequested = 2,
  SubmissionDisabled = 3,
}

export enum ShipmentType {
  Pickup = 1,
  Delivery = 2,
}

export class FormSubmission {
  Id: number = 0
  UserID?: string = undefined
  ShipmentType: ShipmentType = ShipmentType.Pickup
  FirstName: string = ''
  LastName: string = ''
  StreetName: string = ''
  BuildingNumber: string = ''
  Unit: string = ''
  City: string = ''
  LocationID: number = 0
  Kits: FormSubmissionKit[] = []
  IsValidUser: boolean = false
  PhoneNumber: string = ''
}

export class FormSubmissionKit {
  KitTypeCode: string = ''
  NumNeeded: number = 0
}

export class FormSubmissionResponse {
  kits: FormSubmissionResponseKit[] = []
}

export class FormSubmissionResponseKit {
  kitTypeCode: string = ''
  codes: string[] = []
}

export class EndpointResponse {
  status: boolean = false
  reason: ErrorCause = ErrorCause.Unknown
  data: any
}

export class KitDeliveredRequest {
  transactionId: number = 0
  userId: string | undefined = undefined
}

export class UserConfig {
  locations: Location[] = []
  kitsAvailable: KitType[] = []
  kitsOrdered: UserKit[] = []
  appSettings: AppSettings | undefined = undefined
}

export class Location {
  id: number = 0
  name: string = ''
  key: string = ''
  enabled: boolean = true
  cityCode: string = ''
}

export class KitType {
  name: string = ''
  key: string = ''
  enabled: boolean = true
  maxKitsCanUserOrder: number = 0
}

export class UserKit {
  name: string = ''
  key: string = ''
  numKitsOrdered: number = 0
  quotaRemaining: number = 0
}

export class AppSettings {
  deliveryAllowedStartTime: string = ''
  deliveryAllowedEndTime: string = ''
  deliveryAllowedDaysOfWeek: string = ''
}

export class AllowedDeliverySettings {
  DaysOfWeek: Array<number> = []
  StartTimeHour: number = 0
  StartTimeMinute: number = 0
  StartTimeSecond: number = 0
  EndTimeHour: number = 0
  EndTimeMinute: number = 0
  EndTimeSecond: number = 0
}

export interface HomeProps {}

export interface HomeState {
  data: FormSubmission
  isSaving: boolean
  hasSubmitted: boolean
  amountNeededOptions: SelectOption[]
  shippingTypeOptions: SelectOption[]
  cities: SelectOption[]
  validated: boolean
  generatedTokensJson: KitCodesJson
  serverError: string
  confirmAddress: boolean
  ready: boolean
  userConfig?: UserConfig
  yesNoOptions: SelectOption[]
  locationOptions: SelectOptionGroup[]
  pickupCity: string
  lang: string
  kitDelivered: boolean
  invalidDeliveryTime: boolean
}

export function getDefaultHomeState(lang: string): HomeState {
  return {
    data: new FormSubmission(),
    isSaving: false,
    hasSubmitted: false,
    amountNeededOptions: [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
    ],
    shippingTypeOptions: [
      { value: ShipmentType.Pickup, label: AppLabels.ShipmentTypePickup },
      { value: ShipmentType.Delivery, label:  AppLabels.ShipmentTypeDelivery },
    ],
    cities: [
      { value: "KG", label: "Kingston" },
      { value: "PM", label: "Portmore" },
      { value: "MB", label: "Montego Bay" },
    ],
    validated: false,
    serverError: '',
    generatedTokensJson: {},
    confirmAddress: false,
    ready: false,
    invalidDeliveryTime: false,
    yesNoOptions: [
      { value: 1, label: AppLabels.Yes },
      { value: 0, label: AppLabels.No },
    ],
    locationOptions: [],
    pickupCity: '',
    lang: lang,
    kitDelivered: false,
  }
}

export interface KitCodesJson {
  [key: string]: string[]
}

export enum SurveyQuestionType {
  TextField = 1,
  TextNumberField = 2,
  TextDateField = 3,
  SelectField = 4,
  MultiSelectField = 5,
  RadioField = 6,
  CheckField = 7,
  MultiCheckField = 8,
  TextAreaField = 9,
  LikertScaleField = 10,
}

export interface Survey {
  id: number,
  surveyQuestions: SurveyQuestion[]
}

export interface SurveyQuestion {
  id: number,
  questionType: SurveyQuestionType,
  question: string,
  isRequired: boolean,
  groupName:  string,
  sortOrder: number,
  surveyAnswers: SurveyAnswer[]
}

export interface SurveyAnswer {  
  id: number,
  answer: string,
  isOpenTextAnswer: boolean,
  sortOrder: number,
  hideFromSurveyQuestionId?: number,
  hideToSurveyQuestionId?: number
}

export interface SurveySubmission {  
  surveyId: number,
  key: string,
  surveySubmissionAnswers: SurveySubmissionAnswer[]
}

export interface SurveySubmissionAnswer { 
  surveyAnswerId: number,
  otherText: string | undefined
}

export interface SurveyConfirmationState {
  message: string,
  ready: boolean,
  serverError: string | undefined
}

export interface SurveyQuestionHiderParams {
  currentSurveyQuestionId: number, 
  isAnswerSelected: boolean, 
  hideFromSurveyQuestionId: number | undefined, 
  hideToSurveyQuestionId: number | undefined
}